import React from "react";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }

`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ fullSize }) =>
    fullSize &&
    `
    position: fixed;
    top: 10%;
    left: 0;
    right: 0;
    bottom: 0;
    // background-color: rgba(255, 255, 255, 0.9);
  `}
`;

const LoaderContainer = styled.div`
  position: relative;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

const LoaderCircle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: ${({ size }) => size * 0.05}px solid #f3f3f3;
  border-top: ${({ size }) => size * 0.05}px solid
    ${({ color }) => color || "#3498db"};
  border-radius: 50%;
  animation: ${spin} 3s linear infinite;
`;

const LoaderText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: ${({ color }) => color || "#3498db"};
  font-size: ${({ size }) => size * 0.1}px;
  max-width: 80%;
`;

const CustomLoader = ({
  text,
  position = "center",
  color = "#006276",
  fullSize = false,
  size = 100,
}) => {
  const adaptiveSize = fullSize
    ? Math.min(window.innerWidth, window.innerHeight) * 0.6
    : size;

  return (
    <LoaderWrapper fullSize={fullSize}>
      <LoaderContainer size={adaptiveSize}>
        <LoaderCircle color={color} size={size} />
        {text && position === "center" && (
          <LoaderText color={color} size={adaptiveSize * 0.4}>
            {text}
          </LoaderText>
        )}
      </LoaderContainer>
      {text && position !== "center" && (
        <div
          style={{
            position: "absolute",
            [position]: "20px",
            textAlign: "center",
            width: "100%",
            color: color,
            fontSize: `${adaptiveSize * 0.6}px`,
          }}
        >
          {text}
        </div>
      )}
    </LoaderWrapper>
  );
};

export default CustomLoader;
