import AuthConstants from "./../constants/Auth";
import { db, fire } from "./../fire";
import firebase from "firebase";
import UserService from "./User";
import to from "await-to-js";
import moment from "moment";
import SiteConstants from "../constants/Site";
import UserConstants from "../constants/User";
import { updateUserData } from "../store/authentication/AuthenticationStore";
import axios from "axios";
import { getCookie } from "../utils/Utils";
import DatabaseConstants from "./../constants/Database";
class AuthService {
  constructor() {
    this.url = window.location.hostname;
  }
  _get = (key) => {
    if (localStorage && localStorage.getItem(key))
      return JSON.parse(localStorage.getItem(key)) || null;
    if (sessionStorage && sessionStorage.getItem(key))
      return JSON.parse(sessionStorage.getItem(key)) || null;
    return null;
  };
  _set = (key, value, isLocalStorage) => {
    if (isLocalStorage && localStorage)
      return localStorage.setItem(key, JSON.stringify(value));
    if (sessionStorage)
      return sessionStorage.setItem(key, JSON.stringify(value));
    console.warn("Cannot set either local and session browser storage");
    return null;
  };
  _del = (key, isLocalStorage) => {
    if (isLocalStorage && localStorage) return localStorage.removeItem(key);
    if (sessionStorage) return sessionStorage.removeItem(key);
    return null;
  };

  clearLocalStorage = () => localStorage.clear();

  getToken = () => this._get(AuthConstants.OBJ_KEY_JWT_TOKEN);
  getUser = () => this._get(AuthConstants.OBJ_KEY_USER_INFO);
  waitForUserId = () => {
    return new Promise((resolve) => {
      const checkUserId = () => {
        if (_.isString(this.getCurrentUserId())) {
          resolve();
        } else {
          setTimeout(checkUserId, 500);
        }
      };
      checkUserId();
    });
  };
  getUserId = () => {
    if (this.isAuthLocally() || this.isAuth()) return this.getUser()?.uid;
    else return null;
    // !this.isAuth() ? (!this.isAuthLocally ? null : this.getUser().uid) : null;
  };

  getCurrentUserId = () => {
    return firebase.auth().currentUser?.uid;
  };
  setToken = (value, isLocalStorage = true) =>
    this._set(AuthConstants.OBJ_KEY_JWT_TOKEN, value, isLocalStorage);
  setUserData = (value, isLocalStorage = true) =>
    this._set(AuthConstants.OBJ_KEY_USER_INFO, value, isLocalStorage);

  removeToken = () => this._del(AuthConstants.OBJ_KEY_JWT_TOKEN, true);
  removeUserData = () => this._del(AuthConstants.OBJ_KEY_USER_INFO, true);

  // isAuthLocally = () => (this.getToken() == null ? false : true);
  isAuth = () => (firebase.auth().currentUser ? true : false);
  isAuthLocally = () => !!this._get(AuthConstants.OBJ_KEY_USER_INFO);

  /**
   * Method used at App initialization (index.js) to be able to handle any changes in the auth state of the user
   */
  c = (user) => {
    // console.warn("AuthChanged", user)
    console.log("C", user);
    if (user == null && this.isAuth()) {
      console.warn(
        "User is logged out in Firebase but still logged in localy. Let's logout him localy now.",
      );
      // this.logout();
    }
  };

  isValidated = () => {
    let user = firebase.auth().currentUser;

    if (user !== null) return user.emailVerified;
    return;
  };

  isDisabled = () => {
    let user = firebase.auth().currentUser;

    if (user !== null) return user.emailVerified;
    return;
  };

  sendNewEmailVerification = async (data) => {
    const { email, password } = data;
    let user = null;
    fire
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userData) => (user = userData.user))
      .then(() =>
        user.sendEmailVerification({
          url: "https://app.likewatt.com/#/",
        }),
      )
      .then(console.log("mail renvoyé"))
      .catch((e) => console.log("Error:", e));
  };

  sendEmailVerification = async (user) => {
    user
      .sendEmailVerification({
        url: "https://app.likewatt.com/#/",
      })
      .then(console.log("mail renvoyé"))
      .catch((e) => console.log("Error:", e));
  };

  getPublicIP = async () => {
    return new Promise((res, rej) => {
      axios("https://api.ipify.org?format=json")
        .then((response) => {
          if (response?.status === 200 && response?.data?.ip)
            return res(response.data.ip);
          throw new Error("Couldn't retrieve client's public IP");
        })
        .catch(rej);
    });
  };

  login = async (data) => {
    const { email, password } = data;
    let user = null;

    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL); //Prevents reconnection on new tab

    return new Promise((resolve, reject) =>
      // Challenge email and password given with Firebase authentification

      fire
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(async (userData) => {
          user = userData.user;
          const userInDB = await UserService.get(user.uid);
          if (!getCookie("userDataIsUpToDate")) {
            console.log("Setting cookie");
            this.createUserCookie();
          }
          // verify public IP if user has allowedIPs list
          // if (userInDB.allowedIPs) {
          //   // get user connection public IP
          //   let clientPublicIP = "";
          //   try {
          //     clientPublicIP = await this.getPublicIP();
          //   } catch (e) {
          //     await firebase.database().ref(`/users/${user.uid}`).remove();
          //     this.logout();
          //     throw { code: "auth/couldnt-verify-ip" };
          //   }

          //   // if user connection public IP is not in the allowedIPs list then throw error
          //   if (!userInDB.allowedIPs.includes(clientPublicIP)) {
          //     await firebase.database().ref(`/users/${user.uid}`).remove();
          //     this.logout();
          //     throw { code: "auth/unauthorized-ip" };
          //   }
          // }

          // verify if email is verified
          if (user.emailVerified === false)
            throw { code: "auth/unvalidated-email" };
          else return user;
        })

        // Get a JWT Token for later use
        .then(() => {
          return user.getIdToken(true);
        })
        // .then((token) => this.setToken(token))
        // Retrieve additional information of user
        .then(() => UserService.get(user.uid))
        .then((user) => {
          if (user.active === false) throw { code: "auth/inactive-user" };
          else return user;
        })
        // Merge user information retrieve from Firebase auth and Firebase database

        .then((userInfos) => {
          // UserService.updateLastConnected(user.uid);
          if (!userInfos.optimNumber) UserService.updateOptimNumber(user.uid);
          this.refreshUserData();
          return this.setUserData({
            uid: user.uid,
            email: user.email,
            emailVerified: user.emailVerified,
            _lastConnected: moment().format("DD/MM/YYYY HH:mm"),

            ...userInfos,
          });
        })

        .then((e) => resolve(e))
        .catch((e) => {
          return reject(e);
        }),
    );
  };

  signOut = () => {
    fire.auth().signOut();
    this.removeToken();
  };
  logout = () => {
    fire.auth().signOut();

    this.removeToken();
    this.removeUserData();
  };
  createUserCookie = () => {
    const duration = moment.duration(1, "days").as("seconds");
    document.cookie = `userDataIsUpToDate=true; max-age=${duration}; path=/`;
  };

  updateAuthenticationStore = (user) => {
    updateUserData({
      uid: user.uid,
      license: user.license,
      licenseEndDate: user.endDate,
      licenseStartDate: user.startDate,
      domain: user.domain,
      type: user.type,
      email: user.email,
      phone: user.phone,
      managerId: user.managerId,
      isManager: !!user.isManager,
      trackerOkd: user.trackerOkd || false,
      isAbleToChangeParameters: user.isAbleToChangeParameters,
      defaultScenarios: user.defaultScenarios || [],
      defaultRates: user.defaultRates || [],
      subordinates: user.subordinates || [],
    });
  };

  refreshUserData = async () => {
    const localUser = this.getUserId();
    if (!localUser) return;
    const remoteUser = await UserService.get(localUser);
    console.log("refreshing user data");
    this.setUserData({ ...remoteUser });
    this.updateAuthenticationStore(remoteUser);
    this.createUserCookie();
  };
  createAccountWithEmailAndPassword = (data) => {
    const {
      email,
      password,
      firstname,
      lastname,
      company,
      address,
      addressZipCode,
      addressCity,
      phone,
      maxUsers,
      license = null,
      optimNumber = 0,
      allowedNumberOfSites = 0,
      domain = "BTSUP",
    } = data;

    return new Promise((resolve, reject) =>
      // Create a user in the Firebase authentification user list
      fire
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((data) => {
          const { user } = data;
          const { uid } = user;
          // Create a document in Firestore to store additional user information
          UserService.add(uid, {
            uid,
            email,
            firstname,
            lastname,
            company,
            address,
            addressCity,
            addressZipCode,
            license,
            allowedNumberOfSites,
            isAdmin: false,
            isManager: false,
            trackerOkd: false,
            _createdAt: moment().format("DD-MM-YYYY"),
            demo: false,
            emailVerified: false,
            active: true,
            phone,
            maxUsers,
            type: SiteConstants.type.CONSUMER,
            domain: UserConstants.DOMAINS.BTSUP,
          })
            .then(() =>
              user.sendEmailVerification({
                url: "https://app.likewatt.com/#/",
              }),
            )
            .then((e) => resolve(uid))
            .catch((e) => reject(e));
        })
        .catch((e) => {
          reject(e);
        }),
    );
  };

  updateEmail = async (email) => {
    const user = firebase.auth().currentUser;
    return user.updateEmail(email);
  };

  resetPassword = (email) => {
    return fire.auth().sendPasswordResetEmail(email, {
      url: "https://app.likewatt.com/#/signout",
    });
  };
  forceResetPassword = (email, uid) => {
    const url =
      process.env.REACT_APP_ENV === "test"
        ? `https://apitest.likewatt-infra.com/usersAdmin/user/forcePasswordChange/${uid}`
        : `https://api.likewatt-infra.com/usersAdmin/user/forcePasswordChange/${uid}`;
    return fire.auth().sendPasswordResetEmail(email, {
      url: url,
    });
  };
  updatePassword = async (actualPassword, newPassword) => {
    let err = null;
    const user = fire.auth().currentUser;

    [err] = await to(this.checkPassword(actualPassword));
    if (err) return Promise.reject(err);
    return user.updatePassword(newPassword);
  };
  checkPassword = async (passwordToCheck, oldUsersLimit = 1) => {
    let err = null;
    const user = fire.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(
      user.email,
      passwordToCheck,
    );
    await UserService.update(user.uid, {
      maxUsers: firebase.firestore.FieldValue.delete(),
    });
    [err] = await to(user.reauthenticateWithCredential(credential));
    if (typeof oldUsersLimit !== "undefined")
      await UserService.update(user.uid, { maxUsers: oldUsersLimit });

    if (err) return Promise.reject(err);
    Promise.resolve();
  };

  /**
   * Check if the user given is used by fetching the list of providers used by the email
   *
   * @param {*} email Email to check
   * @returns {Promise} Return true if there is at list one provider and false if no provider
   */
  isEmailUsed = async (email) => {
    let err,
      providers = null;

    [err, providers] = await to(fire.auth().fetchSignInMethodsForEmail(email));
    console.log({ providers });
    if (err) {
      console.error(`Cannot check if email is used. Error : ${err.message}`);
      return Promise.reject(err);
    }
    if (providers.length > 0)
      return Promise.reject({ message: "email-already-in-use" });
    return Promise.resolve(!(providers.length === 0));
  };

  createAnonymousAccount = async () => {
    let user = null;
    try {
      user = await fire.auth().signInAnonymously();
      this.setUserData({
        uid: user.uid,
      });
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);
      return user;
    } catch (err) {
      console.log("ERROR IN SIGN IN ANONYMOUS", { err });
      return Promise.reject(err);
    }
  };

  isAdmin = async () => {
    if (this.isAuth()) {
      const uid = firebase.auth().currentUser?.uid;
      if (!uid) return false;

      const user = await UserService.get(uid);
      if (user && user.isAdmin) {
        return true;
      }
    }

    return false;
  };

  getCandI = (url = this.url) =>
    db
      .collection(DatabaseConstants.COLLECTION_AUTH_CNI)
      .doc(url)
      .get()
      .then((doc) => (doc.exists ? { ...doc.data() } : null));
}

export default new AuthService();
