import React from "react";

import LIKEWATT from "../../assets/img/likewatt-blanc-short.png";

import styled from "styled-components";
import { Square } from "../../assets/style/commonStyledComponent";

const Step = styled.div`
  height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const Title = styled.div`
  font-weight: bold;
  padding: 2rem;
  width: 100%;
  margin: 2.8em auto;
`;

const Main = styled.div`
  color: white;
  font-weight: bold;
  padding: 10px 1em;
  height: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: normal;
  max-width: 760px;
  margin: 0 auto;
`;

const Secondary = styled.div`
  color: white;
  padding: 1em;
  padding-top: 0;
  padding-bottom: 10px;

  line-height: normal;
`;
const Emphasis = styled.span`
  font-weight: bold;
  &.underlined {
    text-decoration: underline ${(props) => props.theme.second} 5px;
    text-underline-offset: 5px;
  }
`;

export const ImgContainer = styled.div`
  position: relative;
  height: ${(props) => props.height ?? "50%"};
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin: auto;
`;

export const Image = styled.div`
  width: 35%;
  max-width: 40%;
  height: auto;
  margin: auto;
  ${(props) =>
    `
	margin:0;
	background-image: url(${props.clientLogo ? props.theme.logo : LIKEWATT});
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	${
    props.clientLogo &&
    props.offset &&
    props.theme.logoOffset &&
    `transform : translateY(${props.theme.logoOffset});`
  }
  `}
`;

export const Step1 = () => {
  return (
    <Step id="step-1">
      <Square>
        <Title>
          <Main>
            Bienvenue sur le simulateur de votre
            <br />
            projet d'autoconsommation photovoltaïque
          </Main>
        </Title>
        <Secondary>
          Estimez votre potentiel <br /> d'économie d'énergie{" "}
          <Emphasis className="underlined">en 2 minutes</Emphasis>
        </Secondary>
        <ImgContainer>
          <Image clientLogo offset />
          <Image style={{ paddingBottom: "0.6rem" }} />
        </ImgContainer>
      </Square>
    </Step>
  );
};
