import React, { useContext, useState } from "react";
import { Button } from "react-bulma-components/full";
import FACTURE1 from "../../assets/img/facture1.png";
import FACTURE2 from "../../assets/img/facture2.png";

import landingPV from "../../assets/img/PV.jpg";
import styled from "styled-components";
import { Square } from "../../assets/style/commonStyledComponent";

export const Step2 = (props) => {
  const StepContainer = styled.div`
    height: 98vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align &.step-5 {
      background: url(${landingPV});
    }
  `;

  const Main = styled.div`
    color: white;
    font-weight: bold;
    padding: 10px 1em;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: normal;
    max-width: 760px;
    margin: 0 auto;
  `;

  const Secondary = styled.div`
    color: white;
    padding: 1em;
    padding-top: 0;
    padding-bottom: 10px;
    font-size: 1.3rem !important;
    height: 25%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    .emphasis {
      font-weight: bold;
      &.underlined {
        text-decoration: underline #001b51 5px;
        text-underline-offset: 0.5px;
      }
    }
  `;

  const Img = styled.img`
    position: relative;
    margin: 0 auto;
    &.facture-1 {
      width: 50% !important;
    }
    &.facture-2 {
      width: 90% !important;
    }
  `;

  return (
    <StepContainer>
      <Square>
        <Main>
          Avant de commencer, munissez-vous de votre facture d'électricité
        </Main>
        <Secondary>
          <p>
            1. Repérer le{" "}
            <span className="emphasis">Point de Livraison (PDL)</span> pouvant
            aussi être désigné par Point de Référence Mesure (PRM) ou Référence
            acheminement
          </p>
        </Secondary>
        <Img className="facture-1" src={FACTURE1}></Img>
        <Secondary>
          <p>
            2. Recherchez vos{" "}
            <span className="emphasis">
              tarifs d'achat d'électricité en centimes d'€ / kWh{" "}
            </span>
            , par exemple ci-dessous ce sera 13,06
          </p>
        </Secondary>
        <Img className="facture-2" src={FACTURE2}></Img>
      </Square>
    </StepContainer>
  );
};
